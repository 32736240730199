import React, {FC, useEffect} from "react";
import "./App.css";
import Login from "./Pages/Login";
import LoginIframe from "./Pages/LoginIframe";
import {Route, useLocation} from "react-router-dom";
import LoginSuccess from "./Pages/LoginSuccess";
import Iframe from "./Pages/Iframe";
import Forgot from "./Pages/Forgot";
import Register from "./Pages/Register";
import Pages from "./Constants/Pages";
import {createTheme} from "./theme";
import {ThemeProvider} from "@material-ui/core";
import GlobalStyles from "./Components/GlobalStyles";
import store from "./store/store";
import PasswordSent from "./Pages/PasswordSent";
import SetPassword from "./Pages/SetPassword";
import RegisterFree from "./Pages/RegisterFree/RegisterFree";
import RedirectIfLoggedIn from "./Components/RedirectIfLoggedIn";
import Health from "./Pages/Health";
import LoginIframeSuccess from "./Pages/LoginIframeSuccess";
import {hostNames} from "./interfaces";
import SubscriptionRequired from "./Pages/SubscriptionRequired/SubscriptionRequired";
import RegisterSubmit from "./Pages/RegisterSubmit/RegisterSubmit";
import LoginMagic from "./Pages/LoginMagic/LoginMagic";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App: FC = () => {
    const theme = createTheme({
        direction: "ltr",
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: "LIGHT",
    });

    const themeLoginMagic = createTheme({
        direction: "ltr",
        responsiveFontSizes: true,
        roundedCorners: true,
        theme: "LOGIN_MAGIC",
    });

    const query = useQuery();
    const returnUrl = query.get("returnUrl") ?? "";

    useEffect(() => {
        store.setReturnUrl(returnUrl);
        store.setHost(hostNames[window.location.host.replace('auth.', '')]);
    }, [returnUrl]);


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>

            <div className="App">
                <Route exact={true} path={Pages.LOGIN}>
                    <ThemeProvider theme={themeLoginMagic}>
                        <RedirectIfLoggedIn>
                            <Login/>
                        </RedirectIfLoggedIn>
                    </ThemeProvider>
                </Route>
                <Route exact={true} path={Pages.REGISTER_SUBMIT}>
                    <RedirectIfLoggedIn>
                        <RegisterSubmit/>
                    </RedirectIfLoggedIn>
                </Route>
                <Route exact={true} path={Pages.SUBSCRIPTION_REQUIRED}>
                    <SubscriptionRequired/>
                </Route>
                <Route exact={true} path={Pages.LOGIN_IFRAME}>
                    <LoginIframe/>
                </Route>
                <Route path={Pages.LOGIN_SUCCESS}>
                    <LoginSuccess/>
                </Route>
                <Route path={Pages.LOGIN_MAGIC}>
                    <LoginMagic/>
                </Route>
                <Route path={Pages.LOGIN_IFRAME_SUCCESS}>
                    <LoginIframeSuccess/>
                </Route>
                <Route path={Pages.IFRAME}>
                    <Iframe/>
                </Route>
                <Route path={Pages.HEALTH}>
                    <Health/>
                </Route>
                <Route path={Pages.FORGOT}>
                    <RedirectIfLoggedIn>
                        <Forgot/>
                    </RedirectIfLoggedIn>
                </Route>
                <Route path={Pages.PASSWORD_SENT}>
                    <RedirectIfLoggedIn>
                        <PasswordSent/>
                    </RedirectIfLoggedIn>
                </Route>
                <Route path={Pages.SET_PASSWORD}>
                    <RedirectIfLoggedIn>
                        <SetPassword/>
                    </RedirectIfLoggedIn>
                </Route>
                <Route path={Pages.REGISTER}>
                    <RedirectIfLoggedIn>
                        <Register/>
                    </RedirectIfLoggedIn>
                </Route>
                <Route path={Pages.REGISTER_FREE}>
                    <RedirectIfLoggedIn>
                        <RegisterFree/>
                    </RedirectIfLoggedIn>
                </Route>
            </div>
        </ThemeProvider>
    );
};

export default App;
