import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet-async";
import Loading from "../../Components/Loading";
import useFetch from "../../Hooks/useFetch";
import {useParams} from "react-router-dom";
import Store from "../../store/store";

interface LoginMagicParams {
    guid: string;
}

const LoginMagic: FC = observer(() => {
    const {guid} = useParams<LoginMagicParams>();
    const {isLoading, serverError, fetchNow} = useFetch(`/user/LoginVerifyMagicLink`);

    useEffect(() => {
        (async () => {
            await fetchNow({
                data: {guid},
                onSuccess: (response: any) => {
                    const authToken = response.data;
                    window.location.href = `/user/loginredirect?authToken=${authToken}&returnUrl=${Store.returnUrl}&remember=true`;
                },
                onError:(response: any) => {
                    window.location.href = `/`;
                }
            });
        })()
        
    }, [guid]);

    return (
        <>
            <Helmet>
                <title>Login | BevNET.com</title>
            </Helmet>

            <div>Loading...</div>

            <Loading isLoading={isLoading} />
        </>
    );
});

export default LoginMagic;
